<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_cmdn',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          order: 1,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Jumlah Negara',
          name: 'jml_provinsi',
          dataKey: 'jml_provinsi',
          order: 2,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Jumlah Calon Mahasiswa Regular Full Time/Part Time',
          attributes: { colspan: 2 },
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jtmr',
          dataKey: 'jtmr',
          order: 5,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }],
        [{
          label: 'Laki-laki',
          name: 'jcmr_l',
          dataKey: 'jcmr_l',
          order: 3,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Perempuan',
          name: 'jcmr_p',
          dataKey: 'jcmr_p',
          order: 4,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'text',
          
          placeholder: moment().format('YYYY')
        }, {
          label: 'Jumlah Negara',
          name: 'jml_provinsi',
          dataKey: 'jml_provinsi',
          type: 'text',
          placeholder: 'Indonesia, Jepang, Malaysia'
        }, {
          label: 'Laki-laki',
          name: 'jcmr_l',
          dataKey: 'jcmr_l',
          type: 'number',
          rules: 'required'
        }, {
          label: 'Perempuan',
          name: 'jcmr_p',
          dataKey: 'jcmr_p',
          type: 'number',
          rules: 'required'
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jtmr',
          dataKey: 'jtmr',
          type: 'number',
          rules: 'required'
        }]
      }
    }
  },
}
</script>
